import deLocale from 'element-ui/lib/locale/lang/de'

export default {
  ...deLocale,
  global: {
    search: 'Suche',
    operations: 'Operationen',
    new: 'Neu',
    edit: 'Bearbeiten',
    yes: 'Ja',
    no: 'No',
    category: 'Kategorie',
    cancel: 'Abbrechen',
    copy: 'Kopieren',
    save: 'Speichern',
    delete: 'Löschen',
    data: 'Daten',
    weight: 'Gewicht',
    total_weight: 'Gesamtgewicht',
    price_list: 'Preisliste',
    price_total: 'Gesamtpreis',
    order: 'Auftrag',
    input_placeholder: 'Bitte eingeben',
    input_select_placeholder: 'Auswählen'
  },
  task: {
    title: "Titel",
    description: "Notiz",
    user_id: "Verantwortlicher",
    milestone_id: "Meilenstein",
    due: "Fälligkeitsdatum",
    add_fav: 'Zu Favoriten',
    remove_fav: 'Von Favoriten entfernen',
    project_id: "Projekt",
    MenuItem: 'Menüpunkt',
    new_task_from_selection: 'Aufgabe aus Selektion hinzufügen',
    comments: '{count} Kommentare',
    'grouped-by-user': 'Nach Benutzer gruppiert',
    overdue: 'Nur überfällige',
    due_today: 'Heute fällig',
    checklist: {
      title: 'Checkliste',
      'title-placeholder': 'Neues Element',
      tooltip: '{value}/{of} Aufgaben',
      add: 'Element hinzufügen'
    },
    new: {
      title: 'Neue Aufgabe'
    },
    history: {
      title: 'Historie'
    },
    favorites: 'Priorität',
    card_view: "Kartenansicht",
    all_tasks: 'Alle Aufgaben',
    no_milestones: 'Kein Meilenstein',
    no_team: 'Kein Team',
    subtasks: 'Unteraufgaben',
    'copy-from-project': 'Von Projekt kopieren',
    mark_as_done: 'Aufgabe ist erledigt',
    preview: 'Vorschau',
    recurring_task: 'Wiederkehrende Aufgabe',
    daily: 'täglich',
    weekly: 'wöchentlich',
    monthly: 'monatlich',
    yearly: 'jährlich',
    Monday: 'Montag',
    Tuesday: 'Dienstag',
    Wednesday: 'Mittwoch',
    Thursday: 'Donnerstag',
    Friday: 'Freitag',
    Saturday: 'Samstag',
    Sunday: 'Sonntag',
    every: 'Jeden',
    of_month: 'des Monats',
    always_at: 'Immer am',
    start_date: 'Intervall Beginn',
    end_date: 'Intervall Ende'
  },
  log: {
    type: {
      project: 'Projekt',
      project_order: 'Auftrag',
      position: 'Position',
      material: 'Material',
      material_additional: 'Zusatzliste',
      task: 'Aufgabe',
      subtask: 'Unteraufgabe',
      milestone: 'Milestone',
      project_person: 'Person',
      project_address: 'Einsatzort',
      ride: 'Fahrt',
      option: 'Option',
      comment: 'Kommentar',
      person_address: 'Adresse',
      notification: 'Benachrichtigung'
    },
    operation: {
      C: 'hinzugefügt',
      U: 'geändert',
      D: 'gelöscht'
    },
    user: 'von {name}'
  },
  news: {
    title: 'News',
  },
  material_additional: {
    title: "Zusatzartikel",
    p_address: "Einsatzort",
    ride: "Fahrt",
    quantity: "Menge",
    key: "ID",
    pt_number: "PT Nummer",
    unit: "Einheit",
    width: "Breite",
    height: "Höhe",
    category: "Kategorie",
    bhb_position: "IM Position",
  },
  data_input: {
    save_close: 'Speichern & Schließen',
    save_new: 'Speichern & Neu',
    save_next: 'Speichern & Weiter',
    order_label: 'Auftrag',
    creators_label: 'Ersteller',
    all_orders_label: 'Alle Aufträge',
    all_users_label: 'Alle Benutzer',
    all_creators_label: 'Alle Ersteller',
    all_milestones_label: 'Alle Milestones',
    input_select_order_placeholder: 'Auftrag auswählen',
    address_label: 'Einsatzort',
    input_select_address_placeholder: 'Ort auswählen',
    ride_label: 'Fahrt',
    input_select_ride_placeholder: 'Fahrt auswählen',
    priority_label: 'Priorität setzen',
    priority: 'Besondere Aufmerksamkeit',
    reason_placeholder: 'Grund',
    html_editor_placeholder: 'Projektbeschreibung',
    quality_control: 'Qualitätskontrolle',
    divider_sheet: 'Trennblatt im Export',
    informations: 'Informationen',
    bhb_position: "IM Position",
    category: "Kategorie",
    unit: "Einheit",
    width: "Breite",
    height: "Höhe",
    pt_number: "PT Nummer",
    quantity: "Menge",
    construction: 'Konstruktion',
    assembly: 'Montage',
    material_manager: {
      label: 'Materialmanager',
      materials_empty: 'Keine Materialien',
      constructions_empty: 'Keine Konstruktionen',
      add: 'Material hinzufügen',
      add_construction: 'Konstruktion hinzufügen',
      perimeter_placeholder: 'Umfang (m)',
      area_size_placeholder: 'Fläche (qm)',
      purchase: 'Einkauf',
      purchase_requirement: 'EK-Anforderung',
      unit: 'Einheit',
      piece: 'Stück',
      fixed: 'Pauschal',
      qm: 'qm',
      quantity_label: 'Menge',
      width_label: 'Breite (mm)',
      height_label: 'Höhe (mm)',
      unit_price_label: 'Preis je Einheit',
      price_percent_label: 'Aufschlag in %',
      qm_price_label: 'Preis je qm',
      m_price_label: 'Preis je m',
      total_price_label: 'Preis Gesamt',
      total_price_placeholder: 'Preis Gesamt M1',
      bemerkung_druck: 'Bemerkung Druck',
      bemerkung_dv: 'Bemerkung Druckvorstufe',
      bemerkung_konfektion: 'Bemerkung Konfektion',
      bemerkung_logistik: 'Bemerkung Logistik',
      print_file_path: 'Pfad zur Druckdatei',
    },
    motive: 'Motiv',
    area_label: 'Area',
    input_select_area_placeholder: 'Area auswählen',
    position_label: 'Position IM',
    input_select_position_im_placeholder: 'Position IM auswählen',
    input_select_assembly_placeholder: 'Montage IM auswählen',
    add_area_placeholder: 'Area hinzufügen',
    add_color_placeholder: 'Farbe hinzufügen',
    add_position_placeholder: 'Position hinzufügen',
    add_picking_placeholder: 'Kommissionierungsort hinzufügen',
    designs: 'Designs',
    miscellaneous: 'Sonstiges',
    additional_information_checklist: 'Zusatzinformationen für Checkliste',
    additional_information_checklist_placeholder: 'Zusatzinformationen für Checkliste',
    note_im: 'Bemerkungen IM (DE)',
    note_im_en: 'Bemerkungen IM (EN)',
    note_im_placeholder: 'Bemerkungen IM (DE)',
    note_im_placeholder_en: 'Bemerkungen IM (EN)',
    bemerkung_techniker: 'Bemerkung für Techniker (DE)',
    bemerkung_techniker_en: 'Bemerkung für Techniker (EN)',
    bemerkung_techniker_placeholder: 'Bemerkung für Techniker (DE)',
    bemerkung_techniker_placeholder_en: 'Bemerkung für Techniker (EN)',
    image_editor_placeholder: 'Bitte hochladen',
    input_select_order_placeholder: 'Auftrag auswählen',
    input_select_color_placeholder: 'Farbe auswählen'
  },
  insurance: {
    new: 'Neue Versicherung',
    edit: 'Versicherung bearbeiten',
    ride_label: 'Fahrt',
    input_select_ride_placeholder: 'Fahrt auswählen',
    equipment_included: 'inkl. entsprechendem Equipment',
    piece_module: 'Stück/Module',
    insurance_topic_label: 'Es ist zu versichern:',
    transport: 'Transport',
    reason: 'Grund',
    license_plate: 'Kennzeichen',
    stay_on_site: 'Aufenthalt vor Ort',
    by_apa: 'von APA selbst',
    by_customer: 'vom Kunden',
    value_of_goods: 'Warenwert lt. Auftrag',
    value_currency: 'Euro',
    transport_weight: 'Transportgewicht'
  },
  message: {
    show_nicht_ok: 'Nur "Nicht Ok"',
    show_has_debriefing: 'hat Debriefing',
    show_dv_erstellt: "DV erstellt",
    show_dv_nicht_erstellt: "DV nicht erstellt",
    show_kommisioniert: "Kommisionierte anzeigen",
    "dv-erstellt": "DV Erstellt",
    motive: "Motiv",
    show_more: 'Mehr anzeigen',
    economic_release_and_state_release: 'Wirtschaftlich/Layout',
    grouping: 'Gruppieren nach Area',
    production_costs_total: 'Produktionskosten',
    price_offer: 'Gesamtkosten Projekt',
    show_rejected: 'Abgelehnte anzeigen',
    export_options: 'Export Optionen',
    rel_business: 'Wirtsch. Freigabe',
    'reset-layout': 'Anordnung zurücksetzen',
    'file-size': 'Maximalgröße erreicht. Bitte wählen Sie ein kleineres Bild aus.',
    visibility: 'Sichtbarkeit',
    internal: 'Intern',
    add: 'Hinzufügen',
    titleCoverPage: 'Deckblatt',
    addCoverPage: 'Deckblatt bearbeiten',
    allProjectAddresses: 'Alle Einsatzorte',
    upload_files: 'Dateien hochladen...',
    amount: 'Anzahl',
    functions: 'Funktionen *',
    newLocation: 'Neuer Einsatzort',
    namePlus: 'Name *',
    StreetPlus: 'Straße *',
    Street: 'Straße',
    NumberPlus: 'Hausnummer *',
    Number: 'Hausnummer',
    ZIPPlus: 'PLZ *',
    ZIP: 'PLZ',
    PlacePlus: 'Ort *',
    color: 'Farbe',
    all_colors: 'Alle Farben',
    delivery: 'Anlieferung',
    pickup: 'Abholung',
    'copying-in-progress': 'Kopiervorgang läuft',
    allProjects: 'Alle Projekte',
    Place: 'Ort',
    chat: 'Chat',
    'save_new_ride': 'Speichern & Weiteres Ziel',
    editRide: 'Fahrt bearbeiten',
    newRide: 'Neue Fahrt',
    create_task: 'Aufgabe erstellen',
    chooseVehicleFleet: 'Fahrzeug aus Fuhrpark',
    VehicleFleet: 'Fuhrpark',
    chooseVehicle: 'Fahrzeug auswählen',
    rentalCar: 'Mietfahrzeug',
    driver: 'Fahrer',
    loadingPlus: 'Laden *',
    departurePlus: 'Abfahrt',
    accoEdit: 'Hotel bearbeiten',
    accoSelect: 'Hotel auswählen',
    accoNew: 'Hotel anlegen',
    page_size_all: 'Alle',
    komm_orte: 'Kommissionierungsort',
    from: 'Von:',
    until: 'Bis:',
    unloadingOnSite: 'Abladen vor Ort',
    startOfAssemby: 'Montagebeginn',
    loadingOnSite: 'Aufladen vor Ort',
    unLoadingOnSite: 'Abladen bei APA',
    chooseEinsatzort: 'Einsatzort auswählen',
    DateFromPlus: 'Veranstaltungsdatum von *',
    ChooseDate: 'Termin auswählen',
    ChooseDatePicker: 'Termin auswählen',
    DateUntilPlus: 'bis *',
    DeadlinePrint: 'Deadline Druckdaten *',
    taskEdit: 'Task bearbeiten',
    taskNew: 'Neuer Task',
    editLocation: 'Einsatzort bearbeiten',
    archiveProject: 'Projekt archivieren',
    unarchiveProject: 'Projekt aktivieren',
    createSubproject: 'Unterprojekt erstellen',
    createOrder: 'Neuer Auftrag',
    updateOrder: 'Auftrag aktualisieren',
    updateOrderOk: 'Auftrag erfolgreich aktualisiert',
    quote: 'Angebot',
    createQuote: 'Neues Angebot',
    updateQuote: 'Angebot aktualisieren',
    updateQuoteOk: 'Angebot erfolgreich aktualisiert',
    costAccount: 'Kostenträger',
    articles: 'Artikel',
    unitPrice: 'Stückpreis',
    artkat: 'Artikel-Kategorie',
    assembly: 'Montage',
    comment_delete_confirm: 'Wollen Sie diesen Kommentar wirklich löschen?',
    assemblyPlanning: 'Montage',
    bhb: 'Implementation Manual',
    bhb_short: 'IM',
    ride: 'Fahrt',
    rides: 'Fahrten',
    editExternal: 'Ansprechpartner bearbeiten',
    get_report: 'Erhält Kundenreport',
    cancel: 'Abbrechen',
    responsible: 'Zuständigkeit',
    notificationContactDelete: 'Wollen Sie diesen Ansprechpartner wirklich löschen?',
    notificationContactDeleted: 'Ansprechpartner gelöscht',
    EditTool: 'Mietgerät bearbeiten',
    ChooseTool: 'Mietgerät auswählen',
    NewTool: 'Neues Mietgerät',
    firstUsageTool: '1. Nutzung von *',
    firstUsageUntil: 'Bis *',
    secondUsageTool: '2. Nutzung von',
    secondUsageUntil: 'Bis',
    notificationToolDelete: 'Wollen Sie dieses Mietgerät wirklich löschen?',
    notificationToolDeleted: 'Mietgerät löschen',
    notificationTeamDelete: 'Wollen Sie diesen Mitarbeiter wirklich löschen?',
    notificationTeamDeleted: 'Mitarbeiter löschen',
    close: 'Schließen',
    back: 'Zurück',
    approval: 'Freigabe?',
    comments: 'Kommentare',
    confirm_ablehnen: 'Wollen Sie diese Position wirklich ablehnen?',
    confirm_freigabe: 'Wollen Sie diese Position wirklich freigeben?',
    confirm_wirtschaftliche_freigabe_on: 'Wollen Sie dieser Position die wirtschaftliche Freigabe erteilen?',
    confirm_wirtschaftliche_freigabe_off: 'Wollen Sie dieser Position die wirtschaftliche Freigabe entziehen?',
    contactPersonCustomer: 'Ansprechpartner Kunde',
    dashboard: 'Dashboard',
    dataInput: 'Dateneingabe',
    date: 'Datum',
    delete: 'Löschen',
    docs: 'Dokumente',
    edit: 'Bearbeiten',
    employee: 'Mitarbeiter',
    error: 'Fehler',
    export: 'Exportieren',
    externals: 'Ansprechpartner Kunde',
    goToProject: 'Zum Projekt',
    hotels: 'Hotels',
    infoPool: 'Informationspool',
    saveAndInvite: 'Speichern & Einladen',
    add_comment: 'Kommentar schreiben',
    informations: 'Informationen',
    internal_comment: 'Interner Kommentar',
    internalNotice: 'Interne Bemerkung',
    loadingList: 'Ladeliste',
    loadTemplate: 'Vorlage laden',
    montage: 'Montage',
    more: 'Mehr',
    name: 'Name',
    emptyProject: 'Leeres Projekt',
    new: 'Neu',
    newExternal: 'Neuer Ansprechpartner',
    newProject: 'Neues Projekt',
    no: 'Nein',
    noData: 'Keine Daten',
    newsfeed: 'Newsfeed',
    notice: 'Bemerkung',
    read: 'Als gelesen markieren',
    mark_as_loaded: 'Als geladen markieren',
    all_projects:'(Alle Projekte)',
    offer: 'Angebot',
    ok: 'Ok',
    not_ok: 'Nicht Ok',
    open: 'Öffnen',
    orderList: 'Einkaufsliste',
    pickingList: 'Kommissionierungsliste',
    pricelist: 'Preisliste',
    project: 'Projekt',
    projectFacts: 'Project Facts',
    projectId: 'Projekt-ID',
    projectInformations: 'Projektinformationen',
    projectname: 'Projektname',
    remark: 'Bemerkung',
    reply: 'Antworten',
    reStorage: 'Rückeinlagerung',
    reStorageList: 'Rückeinlagerung',
    rides: 'Fahrten',
    article: 'Artikel',
    masse: 'Maße',
    thereFrom: 'Anwesend von *',
    thereUntil: 'Bis *',
    save: 'Speichern',
    apply: 'Übernehmen',
    saveProjectAsTemplate: 'Als Vorlage speichern',
    saveProjectNotTemplate: 'Keine Vorlage mehr',
    saveTemplate: 'Als Vorlage',
    teamEdit: 'Mitglied bearbeiten',
    teamNew: 'Neues Mitglied',
    teamMemberPlus: 'Mitarbeiter *',
    teamMemberSelect: 'Mitarbeiter auswählen',
    locationPlus: 'Einsatzort',
    location: 'Einsatzort',
    locationSelect: 'Einsatzort auswählen',
    responsibilityPlus: 'Zuständigkeit *',
    responsibilitySelect: 'Zuständigkeit auswählen',
    settings: 'Einstellungen',
    syncArticle: 'Artikel aus Dynamics einlesen',
    tasks: 'Aufgaben',
    team: 'Team',
    teams: 'Teams',
    transportInsurance: 'Transportversicherung',
    user: 'Benutzer',
    vehicle: 'Fahrzeug',
    vehicles: 'Fahrzeuge',
    additionalInformation: 'Zusatzinformationen',
    vehiclesInformation: 'Fahrzeuginformationen',
    NewVehicles: 'Neues Fahrzeug',
    pt_matching_vehicles: 'Fahrzeug in PT',
    various: 'Sonstige',
    warning: 'Achtung',
    wirtschaftliche_freigabe_success: 'Wirtschaftliche Freigabe erteilt.',
    wirtschaftliche_freigabe_removed: 'Wirtschaftliche Freigabe entzogen.',
    workingAids: 'Mietgeräte',
    additionalWorkingAids: 'Zusatzliste',
    yes: 'Ja',
    login_failed: 'Login fehlgeschlagen',
    no_privacy: 'Bitte akzeptieren Sie Datenschutzbestimmungen und AGB',
    privacy_message: 'Ich habe die <a href="https://www.apa.de/datenschutz/" target="_blank">Datenschutzbestimmungen</a> gelesen und akzeptiere sie.',
    terms_message: 'Ich habe die <a href="https://www.apa.de/agb/" target="_blank">AGB</a> gelesen und akzeptiere sie.',
    saved: 'Gespeichert',
    printData: 'Druckdaten',
    footage: 'Bilder',
    user_saved: 'Benutzer erfolgreich gespeichert.',
    user_saved_error: 'Fehler beim Speichern des Benutzers.',
    roles_rights: 'Rollen und Rechte',
    address: 'Adresse',
    roles: 'Rollen',
    user_roles: 'Benutzerrollen',
    password: 'Passwort',
    password_lost: 'Passwort vergessen?',
    send_forgot: 'Neues Passwort senden',
    password_change: 'Passwort ändern',
    new_password: 'Neues Passwort',
    old_password: 'Altes Passwort',
    email: 'Email',
    position: 'Position',
    personalnummer: 'Personalnummer',
    user_information: 'Benutzerinformationen',
    mail_permission: 'Mail Freigaben',
    mail: {
      daily_report: 'Täglicher Report',
    },
    avatar: 'Avatar',
    copy: 'Kopieren',
    language: 'Sprache',
    lang_de: 'Deutsch',
    lang_en: 'Englisch',
    choose_image: 'Bild auswählen...',
    delete_avatar: 'Avatar löschen',
    confirm_delete_avatar: 'Wollen Sie diesen Avatar wirklich löschen?',
    einsatzort: 'Einsatzort',
    correction: 'Korrektur',
    profile: 'Profil',
    projects: 'Projekte',
    search: 'Suchen',
    login: 'Anmelden',
    logout: 'Abmelden',
    admin: 'Administration',
    user: 'Benutzer',
    overview: 'Übersicht',
    MenuItem: 'Menu item',
    list: 'Liste',
    titleBHBComments: 'Information',
    changePosition: 'Positionierung',
    dimensions: 'Maße',
    rental_construction: 'Mietkonstruktion',
    material: 'Material',
    fire_protection_class: 'Brandschutzklasse',
    weight: 'Gewicht',
    rental_price_per_unit: 'Mietpreis pro Stück',
    price_per_unit: 'Preis pro Stück',
    total_rental_price: 'Mietpreis gesamt',
    price_total: 'Preis Gesamt',
    price: 'Preis',
    materialConstruction: 'Material / Konstruktion',
    korrektur_success: 'Auf Korrektur gesetzt',
    korrektur_done: 'Korrektur erledigt',
    release: 'Freigabe Layout',
    released: 'Freigegeben',
    reject: 'Ablehnen',
    economic_release: 'Wirtschaftliche Freigabe',
    no_economic_release: 'Keine wirtschaftliche Freigabe',
    area: 'Area',
    milestone: 'Meilenstein',
    add_sub_task: 'Neue Unteraufgabe',
    size: 'Größe',
    actionPL: 'Handlung PL',
    noActionPL: 'Keine Handlung PL',
    comNote: 'Kommissioniert / Bemerkung',
    inProduction: 'In Produktion',
    stateOpen: 'Offen',
    invoicing: 'Rechnungstellung',
    deleteAdditionalArticle: 'Zusatzartikel löschen',
    deleteAdditionalArticleQuestion: 'Wollen Sie diesen Zusatzartikel wirklich löschen?',
    actions: 'Aktionen',
    documents: 'Dokumente',
    place_of_action: 'Einsatzort',
    present: 'Anwesend',
    hotel: 'Hotel',
    accommodations: 'Unterbringungen',
    notifications: 'Benachrichtigungen',
    milestones: 'Meilensteine',
    state: 'Status',
    bhbState: {
      'ALLE_FREIGABEN': 'Alle Freigabestatus',
      'ALLE': 'Alle',
      'LEER': 'Leer',
      'STATUS': 'Status',
      'OFFEN': 'Offen',
      'KORREKTUR': 'Korrektur',
      'HANDLUNG_PL': 'Handlung PL',
      'FREIGEGEBEN': 'Freigegeben',
      'IN_PRODUKTION': 'In Produktion',
      'ABGELEHNT': 'Abgelehnt',
      'BEMERKUNG': 'Bemerkung',
      'GELADEN': 'Geladen',
      'ZURUECK': 'Zurück',
      'KOMMISSIONIERT': 'Kommissioniert'
    },
    dueDate: 'Fälligkeit',
    new_milestone: 'Neuer Meilenstein',
    edit_milestone: 'Meilenstein bearbeiten',
    debriefing: 'Debriefing',
    archive: 'Archiv',
    archive_warning: 'Es sind noch {openTasks} offene Aufgaben im Projekt. Soll das Projekt trotzdem archiviert werden?',
    daysRemaining: 'Noch {days} Tage',
    due: 'Fällig seit {diff} Tagen',
    dueAt: 'Fällig am:',
    tasksHeader: 'Aufgaben',
    tasksHeaderDone: '({done} von {taskAmount} erledigt)',
    description: 'Beschreibung',
    mounted: 'Montiert',
    not_mounted: 'Nicht montiert',
    phone: 'Telefon',
    ordered: 'Bestellt',
    orderNr: 'Bestellnummer',
    result: 'Resultat',
    result_customer: 'Resultat Kunde',
    list_checked: 'Liste geprüft',
    comment_unchecked: 'Kommentar wurde noch nicht geprüft.',
    comment_checked_by_pl: 'Kommentar von PL geprüft.',
    komm_checked: 'Geprüft am ',
    komm_checked_by: ' von ',
    bhb_check: 'Kontrolle IM',
    backNote: 'Geprüft / Bemerkung',
    office: 'Büro',
    noEvents: 'Keine Benachrichtigungen',
    noTasks: 'Keine Aufgaben',
    content_release: 'Inhaltliche Freigabe',
    einsatzorte: 'Einsatzorte',
    'change-status': 'Status ändern',
    confirm_produktion: 'Soll die Position auf "In Produktion" gesetzt werden?',
    confirm_action_pl: 'Ist eine Handlung durch den PL nötig?',
    confirm_no_action_pl: 'Ist keine Handlung durch den PL mehr nötig?',
    confirm_open: 'Soll die Position auf "Offen" gesetzt werden?',
    confirm_bulk: 'Sollen die {count} Positionen auf "{state}" gesetzt werden?',
    already_released_warning: 'Diese Position ist bereits freigegeben!\nÄnderungen können nur nach Rücksprache mit der Produktion vorgenommen werden.',
    missingRideInfo: 'Es wurde nicht jeder Fahrt ein Fahrzeug zugeordnet.<br>Bitte zuerst alle Fahrten anlegen.',
    modify_released_confirmed: 'Die Position kann jetzt bearbeitet werden.',
    page_size: 'Seitenlänge',
    page_size_all: 'Alle ({allCount})',
    status_changed_success: 'Status erfolgreich geändert.',
    done: 'Erledigt',
    total: 'Gesamt:',
    totalPrice: 'Gesamtpreis:',
    notificationAllSeen: 'Alle gesehen',
    notificationShowRead: 'Zeige gelesene',
    motive: 'Motiv',
    id: 'ID',
    position: 'Position',
    height: 'Höhe',
    all: 'Alle',
    tools: 'Mietgeräte',
    without_prices: 'Ohne Preise',
    with_prices: 'Preise anzeigen',
    english: 'Englisch',
    english_without_prices: 'Englisch, ohne Preise',
    all_sites_seperately: 'Alle Seiten einzeln',
    labels: 'Labels',
    PTNumberRange: 'PT-Nummernkreis',
    PTOrdertext: 'Kunde & Projekt für PT Auftragstext',
    pt_update: 'PT Update',
    pt_state: 'PT Status',
    Konfektion: 'Konfektion',
    export_success: 'Seiten wurden exportiert.',
    export_pdf_error: 'PDF konnte nicht erzeugt werden: ',
    export_pages_error: 'Seiten konnten nicht exportiert werden: ',
    export_labels_error: 'Labels konnten nicht exportiert werden: ',
    image: 'Bild',
    note: 'Bemerkung',
    fileName: 'Dateiname',
    eventDate: 'Veranstaltungszeitraum',
    showArchived: 'Archivierte Projekte einblenden',
    customerId: 'Kundennummer',
    ladeTermin: 'Ladetermin',
    licensePlate: 'Kennzeichen',
    customerLogo: 'Kunden-Logo',
    chooseCustomer: 'Kunde auswählen',
    transportInsurance: 'Transportversicherungen',
    value: 'Value',
    loadingDate: 'Ladetermin',
    all_positions: 'Alle Positionen',
    select_position: 'Positionen auswählen',
    all_areas: 'Alle Areas',
    chooseOrder: 'Auftrag auswählen',
    notificationProjectIsSaved: 'Projekt erfolgreich gespeichert.',
    notificationProjectCannotSaved: 'Projekt konnte nicht gespeichert werden.',
    notificationProjectIsArchived: 'Projekt erfolgreich archiviert.',
    notificationProjectIsTemplate: 'Dieses Projekt ist nun eine Vorlage.',
    notificationProjectIsNoTemplate: 'Dieses Projekt ist keine Vorlage mehr.',
    notificationRideDelete: 'Wollen Sie diese Fahrt wirklich löschen?',
    notificationRideDelete1: 'Fahrt löschen',
    select_area: 'Area auswählen',
    createDynamicsOffer: 'Dynamics Angebot erstellen',
    offerNotification: 'Alle vorgenannten Preise verstehen sich in EUR rein netto zuzüglich der gesetzlichen Mehrwertsteuer.',
    positionBHB: 'Position IM',
    positionAreaBHB: 'Area & (Position) IM',
    construction: 'Konstruktion',
    unit: 'Einheit',
    width: 'Breite (mm)',
    height: 'Höhe (mm)',
    customer: 'Kunde:',
    'eventDate-from': 'Von',
    'eventDate-to': 'Bis',
    breadcrumb: {
      'new-project': 'Neues Projekt',
      'my-dashboard': 'Mein Dashboard',
      'projects': 'Projekte',
      'dashboard': 'Dashboard',
      'project-facts': 'Project facts',
      'dateneingabe': 'Dateneingabe',
      'brandinghandbuch': 'Implementation Manual',
      'dokumente': 'Dokumente',
      'angebot': 'Angebot',
      'montage': 'Montage',
      'purchase': 'Einkaufsliste',
      'picking-list': 'Kommissionierungsliste',
      'ladeliste': 'Ladeliste',
      'ruecklagerungsliste': 'Rückeinlagerungsliste',
      'tasks': 'Aufgaben',
      'informationspool': 'Informationspool',
      'debriefing': 'Debriefing',
      'uebersicht': 'Einstellungen',
      'admin': 'Administration',
      'archive': 'Archiv',
      'chat': 'Chat',
      'position': 'Position',
      'vehicles': 'Fahrzeuge',
      'vehicles-details': 'Fahrzeugdetails',
      'settings': 'Einstellungen',
      'search': 'Suche',
      'article': 'Artikel',
      'article-details': 'Artikeldetails',
      'hotels': 'Hotels',
      'hotels-details': 'Hoteldetails',
      'user': 'Benutzer',
      'teams': 'Aufgabenteams',
      'teams-details': 'Teamdetails',
    },
    order: 'Auftrag:',
    costUnit: 'Kostenträger',
    costCenter: 'Kostenstelle',
    responsiblePL: 'Zuständiger PL:',
    favorites: 'Favoriten',
    noFavorites: 'Keine Favoriten',
    newUserBrackets: '(Neuer Benutzer)',
    contactPerson: 'Ansprechpartner',
    nameComplete: 'Vorname, Nachname',
    loadNote: 'Geladen / Bemerkung',
    inviteUser: 'Benutzer einladen',
    contactPersonSelect: 'Ansprechpartner auswählen',
    noEntries: 'Keine Einträge',
    export_start: 'Die Erstellung des IM läuft, bitte in wenigen Minuten unter Dokumente ansehen.',
    delete_error: 'Das Löschen dieser Position ist nicht möglich.',
    commStates: {
      OFFEN: 'Offen',
      BEMERKUNG: 'Bemerkung',
      KOMMISSIONIERT: 'Kommissioniert'
    },
    confStates: {
      green: 'Fertig',
      yellow: 'In Bearbeitung',
      'null': 'Offen'
    },
    loadingStates: {
      OFFEN: 'Offen',
      BEMERKUNG: 'Bemerkung',
      GELADEN: 'Geladen'
    },
    mountedStates: {
      ALLE: 'Alle Montagestatus',
      OFFEN: 'Offen',
      MONTIERT: 'Montiert'
    },
    reStorageStates: {
      OFFEN: 'Offen',
      BEMERKUNG: 'Bemerkung',
      ZURUECK: 'Zurück'
    },
    ptStatusStates: {
      done: 'Fertig',
      progress: 'In Bearbeitung',
      open: 'Offen'
    },
    uploadMessage: 'Place files here to upload',
    'delete-mat': '{title} löschen',
    'reset-mat': '{title} zurücksetzen',
    work: 'Arbeitsbericht'
  },
  notifications: {
    clear: {
      title: 'Als gelesen markieren',
      message: 'Wollen Sie alle Benachrichtungen als gelesen markieren?'
    },
    image: {
      saved: 'Bild erfolgreich gespeichert.'
    },
    notification: '{project} Benachrichtigung',
    notification_desc: '{user_name} hat etwas Interessantes getan ({type}/{operation}).',
    comment: 'Kommentar',
    comment_desc: `Du wurdest in einem Kommentar erwähnt von {user_name}.`,
    comment_desc_project: `{user_name} hat einen Kommentar verfasst.`,
    comment_mentioned_desc: `{user_name} hat {listener_name} in einem Kommentar erwähnt.`,
    comment_comm: 'Bemerkung auf Kommissionierung',
    comment_comm_desc: '{user_name} hat die Kommissionierung kommentiert.',
    comment_purchase: 'Bemerkung auf Einkauf',
    comment_purchase_desc: '{user_name} hat den Einkauf kommentiert.',
    comment_loading: 'Bemerkung auf Ladeliste',
    comment_loading_desc: '{user_name} hat die Ladung kommentiert.',
    comment_rueck: 'Bemerkung auf Rücklagerung',
    comment_rueck_desc: '{user_name} hat die Rücklagerung kommentiert.',
    newsfeed_new: 'Es gibt einen neuen Eintrag',
    bhb_state: 'Neuer Zustand von {key}: {note}',
    bhb_state_desc: '{user_name} hat den Zustand geändert.',
    mounted: 'Montage von {key}',
    mounted_desc: '{user_name} hat die Position als montiert gekennzeichnet.',
    rel_business: 'Wirtschaftliche Freigabe von {key}',
    rel_business_desc: '{user_name} hat die Position wirtschaftlich freigegeben.',
    task_new: 'Dir wurde eine neue Aufgabe zugewiesen von {user_name}',
    task_assigned: 'Aufgabe wurde von {user_name} neu zugeordnet',
    task_done: '{prj}: Aufgabe wurde von {user_name} erledigt',
    task_done_project: 'Aufgabe wurde von {user_name} erledigt',
    project_person_created: 'Du wurdest zu einem Projekt eingeladen von {user_name}',
    project_person_created_project: 'Ein neues Teammitglied wurde von {user_name} hinzugefügt',
    project_person_added_project: '{user_name} hat {new_member_name} zum Team hinzugefügt.'
  },
  milestone: {
    'veranstaltungszeitraum': 'Veranstaltungszeitraum',
    'deadline-druck': 'Deadline Druckdaten',
    'lade-termin': 'Ladetermin',
     state: 'Status'
  },
  internalPersonTopics: {
    'Senior-Projektleiter': 'Senior Projektleiter',
    'Projektleiter': 'Projektleiter',
    'Grafiker': 'Grafiker',
    'Techniker-Koordinator': 'Techniker-Koordinator',
    'Techniker': 'Techniker',
    'Bereichsleiter': 'Bereichsleiter',
    'Projektbetreuer': 'Projektbetreuer'
  },
  externalPersonTopics: {
    'Druckdaten': 'Druckdaten',
    'Gesamtkoordination': 'Gesamtkoordination',
    'Sicherheit': 'Sicherheit',
    'Hospitality': 'Hospitality',
    'Techniker': 'Techniker-Koordinator',
    'Medien': 'Medien',
    'Ticketing': 'Ticketing',
    'Stadionbetreiber': 'Stadionbetreiber'
  },
  tasks: {
    'show-done-tasks': 'Erledigte anzeigen',
    'no-project': '(Ohne Projekt)'
  },
  project: {
    functions: {
      D: 'Dateneingabe',
      B: 'IM',
      A: 'Angebot',
      M: 'Montage',
      E: 'Einkaufsliste',
      K: 'Kommissionierungsliste',
      L: 'Ladeliste',
      U: 'Rückeinlagerungsliste',
      I: 'Informationspool',
      T: 'Aufgaben',
      R: 'Debriefing',
      C: 'Chat'
    },
    komm_orte: {
      s1: 'Schütte 1',
      s2: 'Schütte 2',
      s3: 'Schütte 3',
      s4: 'Schütte 4',
      s5: 'Schütte 5',
      s6: 'Schütte 6',
    },
    is_private: "Vertrauliches Projekt",
    pt_sync_enabled: "Projekt mit PTworkflow synchronisieren"
  },
  position: {
    'go-to-bhb': 'Zur IM Seite',
    im_position: "IM Position",
    category: "Kategorie",
    unit: "Einheit",
    width: "Breite",
    height: "Höhe",
    pt_number: "PT Nummer",
    quantity: "Menge",
    construction: 'Konstruktion',
    assembly: 'Montage',
    material: 'Material',
    go_to_im: 'Zur IM Seite',
    price_unit: 'Preis pro Einheit',
    dialog: {
      delete: {
        title: 'Position löschen',
        message: 'Wollen Sie diese Position wirklich löschen?'
      }
    }
  },
  comment: {
    action: {
      'position/state_bhb': 'Statusänderung',
      'position/state_bhb/ALLE': 'Alle',
      'position/state_bhb/LEER': 'Leer',
      'position/state_bhb/OFFEN': 'Offen',
      'position/state_bhb/KORREKTUR': 'Korrektur',
      'position/state_bhb/HANDLUNG_PL': 'Handlung PL',
      'position/state_bhb/FREIGEGEBEN': 'Freigegeben',
      'position/state_bhb/IN_PRODUKTION': 'In Produktion',
      'position/state_bhb/ABGELEHNT': 'Abgelehnt',
      'task/create': 'Aufgabe erstellt',
      'project_person/create': 'Teammitglied hinzugefügt',
      'comment/mention': 'Erwähnungen',
      'comment/newsfeed': 'Newsfeed',
      'material/komm_bemerkung': 'Bemerkungen Kommissionierung',
      'material/purchase_bemerkung': 'Bemerkungen Einkauf',
      'material/lade_bemerkung': 'Bemerkungen Ladeliste',
      'material/rueck_bemerkung': 'Bemerkungen Montiert',
      'material_additional/komm_bemerkung': 'Bemerkungen Zusatzliste',
      'position/mounted': 'Montiert',
      'position/rel_business': 'Wirtschaftliche Freigaben',
      'task/assign': 'Tasks zugewiesen',
      'task/done': 'Tasks erledigt'
    }
  }
}
